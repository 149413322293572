@use './reset' as *;
@use './mixins' as *;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600&family=Open+Sans:wght@300;400&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background-color: #F5F7FB;
  color: #323232;
}