@use './../../partials/mixins' as *;


.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet {
    margin: 2rem 0 5rem 1rem;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }

  @include desktop {
    margin: 4rem 0 5rem 5rem;
  }


  &__photo {
    width: 90vw;
    height: 115vw;
    max-width: 20rem;
    max-height: 27.2rem;
    min-width: 18rem;
    min-height: 23rem;
    position: relative;

    &::after {
      content: "";
      width: 99.5%;
      height: 99.5%;
      top: 12px;
      right: -12px;
      position: absolute;
      border: 1px solid #2a4fbf;
    }


    @include tablet {
      max-width: 23.88rem;
      max-height: 30.84rem;
      width: 29vw;
      height: 39vw;
      min-width: 18rem;
      min-height: 24rem;
      margin-right: 3rem;
    }

    @include desktop {
      min-width: 20.90rem;
      min-height: 28.57rem;
      max-width: 23.57rem;
      max-height: 33.57rem;
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #2a4fbf;
      padding: 2rem 1.5rem 1.5rem 2rem;
    }
  }

  &__actions {
    padding: 2rem 0 2rem 0.5rem;
    display: flex;

    &__button-social {
      outline: none;
      border: 1px solid #FFFFFF;
      padding: 0.6rem;
      background-color: #2a4fbf;
      color: #FFFFFF;
      font-size: 1rem;
      border-radius: 2rem;
      cursor: pointer;
      display: flex;
      margin-right: 0.5rem;
      text-decoration: none;

      @include tablet  {
        font-size: 1.1rem;
      }
    }
  }

  &__content {
    padding-top: 2rem;
    width: 90vw;
    min-width: 18rem;
    max-width: 30rem;

    @include tablet {
      width: 25rem;
    }

    @include desktop {
      width: 30rem;
    }

    &__title {
      font-size: 1.7rem;
      margin-bottom: 1.4rem;
      font-weight: 600;

      @include tablet {
        font-size: 2.5rem;
        margin-bottom: 2.1rem;
      }
    }

    &__subtitle {
      color: #2a4fbf;
      font-size: 1.2rem;
      margin-bottom: 0.9rem;
      line-height: 1.5rem;

      @include tablet {
        font-size: 1.5rem;
        margin-bottom: 1.3rem;
        line-height: 2rem;
      }
    }

    &__text {
      font-size: 0.9rem;
      font-weight: 300;
      line-height: 1.3rem;
      margin-bottom: 1.7rem;


      @include tablet {
        font-size: 1.1rem;
        font-weight: 300;
        line-height: 1.6rem;
        margin-bottom: 2.1rem;
      }
    }

    &__technologies {
      padding: 2rem 1rem 1.5rem 2rem;
      border: 1px solid #2a4fbf;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      justify-content: space-evenly;

      &::after {
        content: "";
        width: 99.5%;
        height: 99.5%;
        top: 12px;
        right: -12px;
        position: absolute;
        border: 1px solid #2a4fbf;
      }

      &__item {
        padding: 0.5rem;
        margin: 0.5rem 0.5rem;
        background-color: #FFFFFF;
        border-radius: 0.5rem;

        &__icon {
          font-size: 2.5rem;

          @include tablet {
            font-size: 3rem;
          }

          @include desktop {
            font-size: 3.5rem;
          }
        }
      }
    }
  }
}