@use './../../partials/mixins' as *;

.profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;


  @include tablet {
    align-items: center;
    padding-top: 4rem;
    flex-direction: row;
    justify-content: center;
  }

  &__photo {
    width: 79vw;
    height: 105vw;
    max-width: 20rem;
    max-height: 27.2rem;
    min-width: 15.8rem;
    min-height: 21rem;
    position: relative;


    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #2a4fbf;
      padding: 2rem 1.5rem 1.5rem 2rem;
    }

    &::after {
      content: "";
      width: 99.5%;
      height: 99.5%;
      top: 12px;
      right: -12px;
      position: absolute;
      border: 1px solid #2a4fbf;
    }

    @include tablet {
      margin-right: 3.5rem;
      width: 42vw;
      height: 55vw;
      max-width: 25.9rem;
      max-height: 35.57rem;
    }

    @include desktop {
      min-width: 24rem;
      min-height: 32rem;
      max-width: 26.36rem;
      max-height: 35.14rem;
      width: 30vw;
      height: 40vw;
    }
  }

  &__info {
    width: 100%;
    min-width: 14.375rem;
    padding-top: 1.5rem;
    /*width: 81vw;*/
    max-width: 22rem;

    @include tablet {
      width: 25rem;
    }


    &__heading {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-weight: 600;

      @include tablet {
        font-size: 2rem;
      }

      @include desktop {
        font-size: 2.1rem;
      }
    }

    &__sub-heading {
      font-size: 0.8rem;
      margin-bottom: 0.9rem;

      @include tablet {
        font-size: 1.6rem;
        margin-bottom: 1.6rem;
      }

      @include desktop {
        font-size: 1.65rem;
      }
    }

    &__paragraph {
      font-size: 0.8rem;
      font-weight: 300;
      margin-bottom: 1rem;
      line-height: 1.5rem;

      @include tablet {
        margin-bottom: 1.5rem;
        font-size: 1.1rem;
      }

      @include desktop {
        margin-bottom: 1.6rem;
        font-size: 1.25rem;
        line-height: 1.6rem;
      }

    }

    &__action {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      @include tablet {
        justify-content: space-between;
      }

      &-button {
        @include button_basic;
        padding: 0.5rem;
        font-size: 0.9rem;
        background-color: #FFFFFF;
        color: #2a4fbf;

        &:hover {
          background-color: #2a4fbf;
          color: #FFFFFF;
        }

        @include tablet {
          display: flex;
        }

        @include desktop {
          font-size: 1.1rem;
        }

        &--cta {
          display: flex;
          background-color: #2a4fbf;
          color: #FFFFFF;
          margin-left: auto;
          align-items: center;
          text-decoration: none;

          &:hover {
            background-color: #FFFFFF;
            color: #2a4fbf;
          }
        }

        &--social {
          display: flex;
          margin-right: 0.5rem;
          border-radius: 2rem;
          text-decoration: none;
          font-size: 1rem;
          padding: 0.5rem;

          @include tablet {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}