@use "./../../partials/mixins" as *;

.project {
  width: 100%;
  background-color: #FFFFFF;
  padding: 1rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 14%);

  @include tablet {
    padding: 2rem 4rem;
  }

  @include desktop {
    padding-top: 4rem
  }

  &__link {
    @include basic_link;
    margin-bottom: 1rem;
    display: flex;
    color: #2a4fbf;
    cursor: pointer;

    @include tablet {
      display: none;
    }
  }

  &__img {
    height: 6rem;
    width: 100%;
    background-size: cover;
    background-position: bottom;
    background-image: url('https://portfolioapps31.blob.core.windows.net/partyagile/initial-page-image-1.jpg');
    margin-bottom: 2rem;

    &--ecommerce {
      background-image: url('https://portfolioapps31.blob.core.windows.net/portfolio/ecommerce_photo.jpg');
    }

    &__overlay {
      width: 100%;
      height: 100%;
      background-color: rgba(50,50,50,0.1);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem;
      color: #2a4fbf;

      &__title {
        font-size: 1.6rem;
        color: #2a4fbf;
        margin-bottom: 0.5rem;
        font-weight: 600;
      }

      &__text {
        font-size: 0.78rem;
      }
    }
  }

  &__text {
    font-size: 0.9rem;
    line-height: 1.7rem;
    margin-bottom: 1.5rem;
    font-weight: 300;

    @include tablet {
      font-size: 1.1rem;
    }

    &__span {
      color: #2a4fbf;
      font-weight: 500;
    }
  }

  &__subtitle {
    font-size: 1.2rem;
    color: #2a4fbf;
    margin: 1rem 0;
    font-weight: 600;

    @include tablet {
      font-size: 1.4rem;
    }
  }

  &__images {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    &__item {
      height: 22rem;
      width: 25rem;
      margin-bottom: 2rem;

      &__src {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__action {
    display: flex;
    margin-bottom: 2rem;


    &__links {
      @include button_basic;
      display: flex;
      background-color: #2a4fbf;
      color: #FFFFFF;
      align-items: center;
      margin-right: 1rem;
      text-decoration: none;

      &:hover {
        background-color: #FFFFFF;
        color: #2a4fbf;
      }

      &__icon {
        margin-right: 0.5rem;
      }
    }
  }
}
